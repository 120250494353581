























































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import {
    getWorkflowMessagesStatistics,
} from '@/api/consoleApi/reports'
import { formatNumber } from '@/utils/formatter'
import { UserModule } from '@/store/modules/user'
import { get } from 'lodash'
import { AppModule } from '@/store/modules/app'
import VsWorkflowStatisticsMessageFullQuality from '@/modules/workflows/components/VsWorkflowStatisticsMessageFullQuality/Index.vue'
import VsWorkflowStatisticsMessageFullEngagement from '@/modules/workflows/components/VsWorkflowStatisticsMessageFullEngagement/Index.vue'
import html2pdf from 'html2pdf.js'
import VsDropdownText from '@/components/VsDropdownText/Index.vue'
import { getWorkflowMessage } from '@/api/consoleApi/workflows'
import VsVerticalPercentageBar from '@/components/VsVerticalPercentageBar/Index.vue'

@Component({
    name: 'WorkflowStatisticsMessage',
    components: {
        VsContainer,
        VsFullTopBarLayout,
        VsLoader,
        VsSectionHeader,
        VsWorkflowStatisticsMessageFullQuality,
        VsWorkflowStatisticsMessageFullEngagement,
        VsDropdownText,
        VsVerticalPercentageBar,
    },
})
export default class extends Vue {
    private message: any = null
    private statistics: any = null
    private open = false
    private loading = false

    $refs: any

    get workflowId () {
        return this.$route.params.workflowId || ''
    }

    get user () {
        return UserModule.user
    }

    get canRepotExport () {
        return this.user.configuration.rules.reportExport
    }

    get hasFullAutomationStatistics () {
        return this.user.configuration.rules.automationStatisticFull
    }

    get sentThresholds () {
        return get(AppModule.consoleConf, 'sentThresholds', { unsubscription: 3, bounce: 7 })
    }

    get isUnsubscriptionUnderThreshold () {
        if (!this.statistics) return false
        return this.statistics.unsubscriptions.unsubscription_percentage < this.sentThresholds.unsubscription
    }

    get isBounceUnderThreshold () {
        if (!this.statistics) return false
        return this.statistics.bounce.bounce_percentage < this.sentThresholds.bounce
    }

    get messageId () {
        return this.$route.params.messageId || ''
    }

    get deliveryHeight () {
        if (!this.statistics) return 0
        return this.statistics.sent.sent_percentage
    }

    get openHeight () {
        if (!this.statistics) return 0
        return (this.deliveryHeight / 100) * (this.statistics.delivery.delivery_percentage / 100) * 100
    }

    get clickHeight () {
        if (!this.statistics) return 0
        return (this.openHeight / 100) * (this.statistics.open.or_percentage / 100) * 100
    }

    async beforeMount () {
        this.loading = true
        await this.getMessage()
        await this.getStatistics()
        this.loading = false
    }

    private async getStatistics () {
        try {
            const resp = await getWorkflowMessagesStatistics(this.workflowId, this.messageId)
            this.statistics = resp.data.data
        } catch (e) {
            console.log(e)
        }
    }

    private async getMessage () {
        try {
            const resp = await getWorkflowMessage(this.workflowId, this.messageId)
            this.message = resp.data.data
        } catch (e) {
            console.log(e)
        }
    }

    private formatNumber (value: number) {
        return formatNumber(value)
    }

    async downloadPdf () {
        this.loading = true
        const opt = {
            filename: `${this.message.name}_statistics.pdf`,
            image: { type: 'jpeg', quality: 1 },
            enableLinks: false,
            jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait',
            },
        }
        setTimeout(async () => {
            await html2pdf().from(this.$refs.printContent.$el).set(opt).save()
            this.$refs.printContent.$el.style.width = '100%'
            this.loading = false
        }, 1000)
    }

    private getMinMaxBarsHeight (height: number) {
        if (height < 0) return 0
        if (height > 100) return 100
        return height
    }
}
